.table-responsive{
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background: white;
    border-radius: 5px;
    padding: 10px;
}

.search, .rowNumber{
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 700;
    color:#666;
}

.rowNumber{
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-weight: 700;
  color:#666;
}

.search input{
    width: 300px;
    padding: 10px;
    border: 1px solid #ccc;
    outline: none;
    margin-left: 10px;
    height: 56px;
    color:#666;
}

.rowNumber select{
  margin-left: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  outline: none;
  height: 56px;
}

.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  list-style: none;
}

.page-item{
  margin: 0px 5px;
}

.page-item.active{
  background: #344153;
  color: white;
}

.page-item.active:hover, .page-item:hover{
  background: #344153;
  color: white;
}

.page-item.active .page-link, .page-item:hover .page-link{
  color: white;
}

.page-link{
  color: #344153;
  text-decoration: none;
  border:none;
  background: none;
  font-size:14px;
  padding: 5px 10px;
  cursor:pointer;
  text-align: center;
  margin-left: 0;
}

td {
  max-width: 150px;
}

tr.hidden{
  display:none;
}

.disabled{
  cursor: not-allowed;
  background: var(--gray-color);
}

.disabled:hover button{
  background: var(--gray-color);
  color:#344153 !important;
  cursor: not-allowed;
}

.no-button{
  background: white;
  margin: 0px 5px;
}