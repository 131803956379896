.container-wrap {
  padding: 40px;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-log {
  font-size: 0.8em;
  font-style: italic;
}

.d-flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100%;
}

.width-90 {
  width: 90%;
}

.actions-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
.actions-buttons button {
  display: flex;
  margin-right: 10px;
  width: 40px;
  justify-content: center;
  align-items: center;
}

.form-container {
  background: white;
  padding: 40px 0px;
  color: var(--blueDark);
  font-family: var(--font);
  margin-top: 20px;
  position: relative;
  width: 100%;
  padding: 20px;
  border-radius: 2px;
}

.form-container label {
  font-size: 1rem;
  margin-bottom: 10px;
  font-weight: 600;
  color: var(--blueDark);
  font-family: var(--font);
  background: white;
  display: block;
}

.form-group {
  margin-bottom: 20px;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator.css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator {
  position: absolute;
  right: 10px;
}

.form-control {
  width: 100%;
}

input[type='file'] {
  color: black;
}

.icon-btn svg {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.icon-btn:hover svg {
  transform: scale(1.1);
}

.filter-date {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  color: rgb(47, 47, 47);
  font-weight: 600;
  height: 40px;
}

.filter-date input {
  color: rgb(47, 47, 47);
  margin: 0 10px;
  border: 1px solid #ccc;
}

.btn-secondary {
  background: var(--blueDark);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid var(--blueDark);
  transition: all 0.2s ease-in-out;
  margin: 0 10px;
}

.btn-secondary:hover {
  background: white;
  color: var(--blueDark);
}

.pop_confirmation_input {
  margin-top: 10px;
}

.pop_confirmation_input input::placeholder {
  color: #ccc;
}

.pop_confirmation_input input::-moz-placeholder {
  color: #ccc;
}

.pop_confirmation_input input:-ms-input-placeholder {
  color: #ccc;
}

/* Processes */
.processes-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.process-card {
  background: white;
  padding: 20px;
  border-radius: 5px;
  margin: 10px 5px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  width: 20%;
  color: var(--blueDark);
}

.process-card:hover {
  transform: scale(1.01);
  background: var(--blueDark);
  color: white;
  box-shadow: 0px 0px 10px 0px rgba(255, 255, 255, 0.75);
}

.process-card .process-card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.process-card .process-card-icon {
  font-size: 2rem;
  margin-bottom: 10px;
}

.process-card .process-card-title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.process-card .process-card-text {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 10px;
}

.process-orders-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: white;
  padding: 20px;
  color: var(--blueDark);
  border-radius: 2px;
}

.table-log {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
  background: white;
}

.table-log table {
  width: 100%;
  border-collapse: collapse;
}

.table-log thead {
  color: var(--blueDark);
  text-align: center;
}

.table-log thead th {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.table-log tbody tr {
  border-bottom: 1px solid #ccc;
}

.table-log tbody tr td {
  padding: 10px;
  text-align: center;
  color: var(--blueDark);
}

.pop_confirmarion_input_group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pop_confirmarion_input_group input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.pop_confirmarion_input_group select {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.text-danger {
  color: var(--red-color);
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

html {
  --blueDark: #0d2538;
  --blueLight: #18507e;
  --red-color: #e17373;
  --green-color: #a7dea7;
  --orange-color: #e5c994;
  --font: 'Nunito', sans-serif;
  font-family: var(--font);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font);
}

body {
  background-color: var(--blueDark);
  color: white;
  font-family: var(--font);
}

h1 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 20px 0;
}

h2 {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
  margin: 20px 0;
}

/* form elements */
form {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  padding: 20px;
}

input {
  width: 100%;
  padding: 10px;
  margin: 0 0 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 1rem;
  border: 1px solid white;
  background-color: transparent;
  color: white;
}

button {
  background-color: white;
  color: var(--blueDark);
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  padding: 10px 5px;
  border: 1px solid white;
  border-radius: 5px;
  outline: none;
  font-size: 1rem;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

button:disabled {
  background-color: #ccc;
  color: white;
  cursor: not-allowed;
}

button:disabled:hover {
  background-color: #ccc;
  color: white;
}

button:hover {
  background-color: var(--blueLight);
  color: white;
}

::placeholder {
  color: white;
  opacity: 0.5;
}

::-webkit-input-placeholder {
  color: white;
  opacity: 0.5;
}

::-moz-placeholder {
  color: white;
  opacity: 0.5;
}

label {
  font-size: 1.2rem;
  font-weight: 700;
}

.userforms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.App-logo {
  height: 180px;
  pointer-events: none;
  margin: auto;
  text-align: center;
  display: block;
  margin-top: 20px;
}

.text-center {
  text-align: center !important;
}

.charts-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
  background: white;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  color: #0d2538;
  min-height: 400px;
  flex-direction: column;
}

.apexcharts-menu.apexcharts-menu-open {
  background: #0d2538;
  color: white;
}

.apexcharts-menu-item:hover {
  background: #0b4a87 !important;
  color: white;
}

.create-item-a {
  cursor: pointer;
  color: var(--blueLight);
  font-weight: 600;
}

.create-item-a:hover {
  color: var(--orange-color);
}
