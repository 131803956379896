@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');

html {
  --blueDark: #0d2538;
  --blueLight: #18507e;
  --red-color: #e17373;
  --green-color: #a7dea7;
  --orange-color: #e5c994;
  --font: 'Nunito', sans-serif;
  font-family: var(--font);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: var(--font);
}

body {
  background-color: var(--blueDark);
  color: white;
  font-family: var(--font);
}

.pill {
  color: white;
  padding: 3px 20px;
  border-radius: 1rem;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
}

.danger {
  background-color: var(--red-color);
}

.date-audit.input[type='date']::-webkit-calendar-picker-indicator {
  color: rgb(255, 255, 255) !important;
}

.date-audit {
  color: white;
  background-color: var(--blueLight);
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.bold {
  font-weight: bold !important;
}

.italic {
  font-style: italic !important;
}

.uppercase {
  text-transform: uppercase !important;
}
